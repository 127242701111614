<template>
  <div style="height: 100%; background-color: #ffffff">
    <head-layout
      :head-title="headTitle"
      :head-btn-options="headBtnOptions"
      @head-cancel="headCancel"
    ></head-layout>
    <div class="formContentBox">
        <div class="formMain">
            <el-form
                ref="dataForm"
                :model="dataForm"
                label-width="150px"
                :disabled="formType == 'view'"
            >
                    <div class="formTopic" style="margin-bottom: 12px"><span>企业信息</span></div>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="企业名称" prop="companyName">
                                <el-input v-model="dataForm.companyName" disabled placeholder="请输入企业名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="统一社会信用代码" prop="companyCode">
                                <el-input v-model="dataForm.companyCode" disabled placeholder="请输入统一社会信用代码"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="注册地" prop="regLocation">
                                <el-input v-model="dataForm.regLocation" disabled placeholder="请输入注册地"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="法定代表人" prop="legalRep">
                                <el-input v-model="dataForm.legalRep" disabled placeholder="请输入法定代表人"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="成立日期" prop="curDate">
                                <el-input v-model="dataForm.curDate" disabled placeholder="请输入成立日期"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
            </el-form>
            <div class="formTopic" style="margin-bottom: 12px">
                <span>详情记录</span>
                <el-button size="mini" @click="addBtn" v-if="formType != 'view'">新增</el-button>
            </div>
            <addForm ref="addForm" :companyCode="dataForm.companyCode" :formType="formType"></addForm>
        </div>
    </div>
  </div>
</template>
<script>
import HeadLayout from "@/views/components/layout/head-layout";
import addForm from "./components/addForm.vue"
export default {
  components: {
    HeadLayout,
    addForm
  },
  data() {
    return {
      headTitle: "",
      formType: "",
      dataForm:{}
    };
  },
  computed: {
    headBtnOptions() {
      let result = [];
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    // 初始化新增/编辑/查看页面
    init(){
        let type = this.$route.query.type;
        this.formType = type;
        // companyRow 新增传进来是弹窗中的row；而编辑和查看传进来的是黑名单组织列表中的row；故companyRow在不同操作下点击进来的rowId不同，若有后续处理，需要注意。
        this.dataForm = this.$route.query.companyRow ? JSON.parse(this.$route.query.companyRow) : {}
        if (type == "add") {
            this.headTitle = "新增黑名单组织";
            this.$refs.addForm.addCheckDetail(this.dataForm.companyCode)
        } else if (type == "edit") {
            this.headTitle = "编辑黑名单组织";
            this.$refs.addForm.initDetail(this.dataForm.id)
        } else if (type == "view") {
            this.headTitle = "查看黑名单组织";
            this.$refs.addForm.initDetail(this.dataForm.id)
        }
    },
    // 点击新增按钮
    addBtn(){
        this.$refs.addForm.addModelShow = true
    },
    headCancel() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
}
.formMain{
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 0px 12px;
    height: 100%;
}
.formTopic {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
